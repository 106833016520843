import React from "react"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import { FormattedMessage } from "react-intl"

import Layout from "src/layout"
import { useLang } from "src/layout/root"
import { SIZES } from "src/layout/constants"
import { ImageColumn, Column as BaseCol, Label, Top, Sheet, Specs, Bottom, Text } from "src/components/styled"
import Inspirations from "src/components/Inspirations"
import Spec from "src/components/Spec"
import Download from "src/svg/download.svg"

const Content = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
const Column = styled(BaseCol)`
  flex: 1;
  h1 {
    margin: 0.5em 0;
  }
  min-width: 300px;
  @media (min-width: 1000px) {
    padding: 0 ${SIZES.margin}px;
  }
`

interface DataType {
  materials: { nodes: Material[] }
  deposits: { childImageSharp: Media }
}
interface ContextType {
  reference: string
}

const MaterialPage: GatsbyPage<DataType, ContextType> = ({ data }) => {
  const { getNodes } = useLang()
  const [material] = getNodes<Material>(data.materials)

  return (
    <Layout subMenu="products" pageTitle={material.name}>
      <Top>
        <FormattedMessage id="products.materialTitle" tagName="h1" />
        <FormattedMessage id="products.materialSubtitle" tagName="h2" />
      </Top>
      <Sheet>
        <ImageColumn>
          <Image fluid={material.bigPhoto.fluid} />
        </ImageColumn>
        <Specs>
          <div>
            <h3>{material.name}</h3>
            <FormattedMessage id="material.specs" tagName="h4" />
            <Spec id="material.reference" value={material.reference} />
            {material.specs?.map((spec) => (
              <Spec key={spec.contentful_id} {...spec} />
            ))}
          </div>
          <Bottom>
            <Link to="/catalog/">
              <FormattedMessage id="material.catalog" />
              <br />
              <Download />
            </Link>
          </Bottom>
        </Specs>
      </Sheet>
      <Content>
        <Column>
          <Image fluid={data.deposits.childImageSharp.fluid} />
        </Column>
        <Column>
          <FormattedMessage id="material.depositsLabel" tagName={Label} />
          <FormattedMessage id="material.depositsTitle" tagName="h1" />
          <FormattedMessage id="material.depositsText" tagName={Text} />
        </Column>
      </Content>
      <Inspirations boxed list={material.inspirations} />
    </Layout>
  )
}

export default MaterialPage

export const query = graphql`
  query($reference: String!) {
    materials: allContentfulMatiere(filter: { reference: { eq: $reference } }) {
      nodes {
        node_locale
        reference
        name
        bigPhoto {
          fluid(maxWidth: 1600, quality: 75) {
            ...GatsbyContentfulFluid
          }
        }
        specs {
          contentful_id
          name
          value
          description {
            json
          }
          image {
            fluid(maxWidth: 350, quality: 75) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
        inspirations {
          slug
          photo {
            fluid(maxWidth: 800, quality: 75) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    deposits: file(relativePath: { eq: "material/deposits.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
